import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='SECURE INSURANCE IN WISCONSIN' activeCard='wisconsin' image={'/Wisconsin_BG.jpeg'}>
          <p>
            Wisconsin customers can rely on Apollo Insurance to be the broker that will find them the lowest cost health insurance. We are proud to work with
            companies such as Medica, Anthem, and Ambetter to bring you the health insurance products you need. National General, United Healthcare, and Aliera
            also provide plans that give our clients greater choice when it comes to deciding which affordable health insurance in Wisconsin is right for them.{' '}
          </p>
          <p>
            We begin every client journey by discovering health insurance needs and work tirelessly to find affordable options that fit those needs. Apollo
            Insurance advisors help Wisconsin consumers with their health insurance, financial planning, life insurance, and Medicare solutions. We also work
            with small businesses to locate reliable group insurance plans.
          </p>
          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance shifting to the consumer, gap insurance plans have become the most important and popular plan we sell
            today.{' '}
          </p>

          <span>health insurance quotes in Missouri</span>

          <p>
            The health industry is a rapidly changing and evolving world. Trust the Apollo agents to guide you through these changes and answer any questions
            you have about affordable health insurance in Wisconsin. We will educate you on the fundamentals of your health insurance plan and how you can get
            the most out of your benefits. Contact our experts today!
          </p>
        </Content>
      </div>
    </Layout>
  );
};
